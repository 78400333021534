<template>
  <div>
    <ed-risco :intRiscoId="0" :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdRisco from "@/components/cadastro/risco/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdRisco },
  mounted() {},
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>